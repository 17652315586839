import { FC } from 'react';

import { Link } from '@common/atoms/Link';
import { News } from '@common/clients/api';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { formatNumericDuration } from '@common/utils/DateTimeUtil';
import { ProTag } from '@web/atoms/ProTag';
import { Time, TimeStyle } from '@web/atoms/Time';
import { WebpImage } from '@web/atoms/WebpImage';

import styles from './ViBlock.module.scss';

enum AspectRatio {
    BLOCK_THUMB = 110 / 60,
}

interface Props {
    item: News;
}

export const ViBlock: FC<Props> = ({ item }) => {
    const isProArticle = item.category?.functionality === 'pro';

    const path = item?.host && item?.path ? item.host + item.path : '';
    const title = item?.newsTitle;
    const videoDuration = item?.videofeedItem?.duration
        ? formatNumericDuration(item.videofeedItem.duration)
        : null;

    return (
        <Link href={path} className={`${styles.ViBlock} ${isProArticle ? styles.pro : ''}`}>
            <div className={styles['image-container']}>
                <WebpImage
                    defaultSize={WebpWidthEnum.WIDTH_225}
                    src={item.image}
                    alt={''}
                    className={styles.image}
                    ratio={AspectRatio.BLOCK_THUMB}
                />
                <div className={`${styles.overlay} ${isProArticle ? styles['overlay-gradient'] : ''}`}>
                    {videoDuration ? <Time styleVariant={TimeStyle.DURATION} time={videoDuration} /> : null}
                    {isProArticle ? <ProTag tag={item.category?.name || ''} /> : null}
                </div>
            </div>
            <div className={styles.title}>{title ? <h4>{title}</h4> : null}</div>
        </Link>
    );
};
